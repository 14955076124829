<template>
  <v-row>
    <v-col class="ml-4">
      <h4 style="display: inline" class="mr-4">
        # of Tickets:
        <span style="font-weight: normal">{{ totals.ticketCount }}</span>
      </h4>
      <h4 style="display: inline">
        Total Hours:
        <span style="font-weight: normal">{{ totals.hours }}</span>
      </h4>

      <h3 class="text-decoration-underline mt-3">Revenue</h3>
      <h4 class="ml-5">
        Truck Revenue:
        <span style="font-weight: normal">${{ formatThousands(revenue.truck) }}</span>
      </h4>
      <h4 class="ml-5">
        Fuel Surcharge:
        <span style="font-weight: normal">${{ formatThousands(revenue.fuel) }}</span>
      </h4>
      <h4 class="ml-5">
        Travel Charges:
        <span style="font-weight: normal">${{ formatThousands(revenue.travel) }}</span>
      </h4>
      <h4 class="mb-3 ml-10">
        Subtotal
        <span style="font-weight: normal">${{ formatThousands(revenue.subtotal) }}</span>
      </h4>

      <h3 class="text-decoration-underline">Deductions</h3>
      <h4 class="ml-5">
        Truck Commision:
        <span style="font-weight: normal">${{ formatThousands(deductions.truck ? deductions.truck : 0) }}</span>
      </h4>
      <h4 class="ml-5">
        Fuel Surcharge Commission:
        <span style="font-weight: normal">${{ formatThousands(deductions.fuel ? deductions.fuel : 0) }}</span>
      </h4>
      <h4 class="mb-3 ml-10">
        Subtotal
        <span style="font-weight: normal">${{ formatThousands(deductions.subtotal) }}</span>
      </h4>
      <h3 class="text-decoration-underline">After Commmision</h3>

      <h4 class="ml-5">
        Truck Revenue:
        <span style="font-weight: normal">${{ formatThousands(afterCommission.truck) }}</span>
      </h4>
      <h4 class="ml-5">
        Fuel Surcharge:
        <span style="font-weight: normal">${{ formatThousands(afterCommission.fuel) }}</span>
      </h4>
      <h4 class="ml-5">
        Travel Charges:
        <span style="font-weight: normal">${{ formatThousands(afterCommission.travel) }}</span>
      </h4>
      <h4 class="mb-4 ml-10">
        Subtotal
        <span style="font-weight: normal">${{ formatThousands(afterCommission.subtotal) }}</span>
      </h4>
      <h3 class="font-weight-bold">
        Payment Subtotal:
        <span style="font-weight: normal">${{ formatThousands(totals.subtotal) }}</span>
      </h3>
      <h3 class="font-weight-bold">
        GST (5%):
        <span style="font-weight: normal">${{ formatThousands(totals.gst) }}</span>
      </h3>
      <h3 class="font-weight-bold mb-5">
        Total Amount:
        <span style="font-weight: normal">${{ formatThousands(totals.total) }}</span>
      </h3>
    </v-col>
  </v-row>
</template>

<script>
import { formatThousands, roundTo } from '../../js/helpers.js'
import TotalsCard from '../components/TotalsCard.vue'

export default {
  page: {
    name: 'CBS Totals',
  },
  components: {
    TotalsCard,
  },
  props: {
    ticketList: Array,
  },
  data() {
    return {
      totals: {
        ticketCount: 0,
        hours: 0,
        revenue: 0,
        discount: 0,
        fuelSurcharge: 0,
        fuelSurchargeAfterCommission: 0,
        excludingFuelAndTravel: 0,
        travelCharges: 0,
        subtotal: 0,
        gst: 0,
        total: 0,
      },
      revenue: { truck: 0, fuel: 0, travel: 0, subtotal: 0 },
      deductions: { truck: 0, fuel: 0, subtotal: 0 },
      afterCommission: { truck: 0, fuel: 0, travel: 0, subtotal: 0 },
      uniqueTickets: [],
    }
  },
  methods: {
    formatThousands,
    clearTotals: function () {
      this.totals = {
        ticketCount: 0,
        hours: 0,
        revenue: 0,
        discount: 0,
        fuelSurcharge: 0,
        fuelSurchargeAfterCommission: 0,
        excludingFuelAndTravel: 0,
        travelCharges: 0,
        subtotal: 0,
        gst: 0,
        total: 0,
      }
      this.revenue = { truck: 0, fuel: 0, travel: 0, subtotal: 0 }
      this.deductions = { truck: 0, fuel: 0, subtotal: 0 }
      this.afterCommission = { truck: 0, fuel: 0, travel: 0, subtotal: 0 }
      this.uniqueTickets = []
    },
    calculateTicketTotal: function () {
      try {
        this.clearTotals()

        //loop the ticket array and get count of unique ticket numbers.
        this.uniqueTickets = [...new Map(this.ticketList.map(item => [item.ticketNum, item])).values()]
        this.totals.ticketCount = this.uniqueTickets.length
        this.calculateTotalHours()
      } catch (err) {
        console.log(`Error calculating unique tickets: ${err}`)
      }
    },
    calculateTotalHours: function () {
      try {
        //loop the unique tickets to start getting the total hours and fluid.
        this.uniqueTickets.forEach(ticket => {
          this.totals.hours += ticket.ticketHours
        })
        this.totals.hours = roundTo(this.totals.hours, 2)
        this.calculateFinancialTotals()
      } catch (err) {
        console.log(`Error getting total hours and fluid: ${err}`)
      }
    },
    calculateFinancialTotals: function () {
      try {
        //loop through all of the tickets and get calculate financial totals.
        this.ticketList.forEach(ticket => {
          //if the product is fuel surcharge, then add the value to the fuel surcharge total.
          this.totals.fuelSurcharge += ticket.fuelSurchargeSubtotal
          //also add the fueld surcharge after commission using the line total after commission.
          this.totals.fuelSurchargeAfterCommission += Number(ticket.fuelSurchargeSubtotal * (1 - ticket.commissionRate))
          //if the product is travel, we sum it to the travel charges.
          if (ticket.product == 'Travel Charge (Rocky)') {
            this.totals.travelCharges += ticket.lineTotal
          }

          this.totals.revenue += ticket.subtotal
          this.totals.discount += ticket.discount
          this.totals.subtotal += ticket.lineTotal
        })
        this.totals.fuelSurcharge = roundTo(this.totals.fuelSurcharge, 2)
        this.totals.fuelSurchargeAfterCommission = roundTo(this.totals.fuelSurchargeAfterCommission, 2)
        this.totals.travelCharges = roundTo(this.totals.travelCharges, 2)
        this.totals.revenue = roundTo(this.totals.revenue, 2)
        this.totals.discount = roundTo(this.totals.discount, 2)
        this.totals.subtotal = roundTo(this.totals.subtotal, 2)
        this.totals.gst = roundTo(this.totals.subtotal * 0.05, 2)
        this.totals.total = roundTo(this.totals.subtotal + this.totals.gst, 2)

        // this.totals.gst += this.totals.subtotal * 0.05
        // this.totals.total += this.totals.subtotal + this.totals.gst

        this.totals.excludingFuelAndTravel = roundTo(
          this.totals.revenue -
            this.totals.discount -
            this.totals.fuelSurchargeAfterCommission -
            this.totals.travelCharges,
          2,
        )

        //calculate individual categories
        this.revenue = {
          truck: roundTo(this.totals.revenue - this.totals.fuelSurcharge - this.totals.travelCharges, 2),
          fuel: this.totals.fuelSurcharge,
          travel: this.totals.travelCharges,
          subtotal: this.totals.revenue,
        }
        //Deductible Revenue = Truck Revenue + Fuel Surcharge Revenue
        //Travel charge revenue is not included, as we don't take deductions on that.
        //determine percentage of revenue related to truck and fuel surcharge

        let truckPercentage = this.revenue.truck / (this.revenue.truck + this.revenue.fuel)
        let fuelPercentage = this.revenue.fuel / (this.revenue.truck + this.revenue.fuel)

        this.deductions = {
          truck: roundTo(this.totals.discount * truckPercentage, 2),
          fuel: roundTo(this.totals.discount * fuelPercentage, 2),
          subtotal: this.totals.discount,
        }

        this.afterCommission = {
          truck: this.totals.excludingFuelAndTravel,
          fuel: this.totals.fuelSurchargeAfterCommission,
          travel: this.totals.travelCharges,
          subtotal: this.totals.subtotal,
        }

        this.$emit(
          'updateTotals',
          {
            ticketCount: this.totals.ticketCount,
            hours: this.totals.hours,
            revenue: this.totals.revenue,
            discount: this.totals.discount,
            fuelSurcharge: this.totals.fuelSurcharge,
            fuelSurchargeAfterCommission: this.totals.fuelSurchargeAfterCommission,
            excludingFuelAndTravel: this.totals.excludingFuelAndTravel,
            travelCharges: this.totals.travelCharges,
            subtotal: this.totals.subtotal,
            gst: this.totals.gst,
            total: this.totals.total,
          },
          this.revenue,
          this.deductions,
          this.afterCommission,
        )
      } catch (err) {
        console.log(`Error calculating financial totals: ${err}`)
      }
    },
  },
  mounted() {
    this.calculateTicketTotal()
  },
  watch: {
    ticketList: function () {
      //calculate all of the new totals.
      this.calculateTicketTotal()
    },
    totals: function () {
      // emitting the totals to use in ContractorBalanceSheet view so that we can use the total calculations
      // in the exportToExcel component
      this.$emit('set-totals-calculations', this.totals)
    },
  },
}
</script>
